import { Datagrid, DateField, DateInput, FunctionField, List, ListContextProvider, SelectField, SelectInput, TextField, TextInput, UrlField, useList, useRecordContext } from "react-admin";
import { SmppStatuses } from "../../models/smppStatuses";
import * as XLSX from 'xlsx';


export const S3serviceList = () =>{
    return (
        <List exporter={false}>
            <Datagrid rowClick="show" sort={{ field: "Created", order: "DESC" }}>
                <TextField source="ServiceName" />
                <DateField source='LastRunDate' showTime={true} />
                <TextField source="State" />
            </Datagrid>
        </List>
    );
}